import React from "react";
import "App.css";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import Home from "Pages/Home/Home.js";
import BasicLayout from "Layouts/BasicLayout/Index";
import ContactUs from "Pages/ContactUs/ContactUs";
import AboutUs from "Pages/AboutUs/AboutUs";
import InvestorWeek from "Pages/InvestorWeek/InvestorWeek";
import AwardPage from "Pages/Award/AwardPage";
import Policy from "Pages/Award/Components/Policy";
import VideographyPage from "Pages/Award/Components/Pages/VideographyPage";
import PhotographyPage from "Pages/Award/Components/Pages/PhotographyPage";
import DrawingPage from "Pages/Award/Components/Pages/DrawingPage";
import FAQsPage from "Pages/Award/Components/Pages/FAQsPage";
import "animate.css";
import HelmetWrapper from "Components/HelmetWrapper/HelmetWrapper";
import useHelmet from "Hooks/useHelmet";
import AwardThankYouPage from "./Pages/Award/AwardThankYouPage";
import IdeasToParticipateInTheKhalijiSmartInvestorAwardPage from "Pages/IdeasToParticipateInTheKhalijiSmartInvestorAward/IdeasToParticipateInTheKhalijiSmartInvestorAwardPage";
import SponserForm from "Pages/Sponsers/SponsersForm";
import InstitutionsPage from "Pages/Institutions/InstitutionsPage";
import PrevWinnerPage2022 from "Pages/PrevWinners/PrevWinnersPage2022";
import PrevWinnerPage2023 from "Pages/PrevWinners/PrevWinnersPage2023";

export default function App() {
  let { path } = useRouteMatch();
  const history = useHistory();
  const pathName = history?.location?.pathname;
  const { helmetState } = useHelmet(pathName);

  return (
    <BasicLayout>
      <HelmetWrapper {...helmetState} />
      <Switch>
        <Route exact path={`${path}`} component={Home} />
        <Route exact path={`${path}contact`} component={ContactUs} />
        <Route exact path={`${path}sponsers`} component={SponserForm} />
        <Route
          exact
          path={`${path}institutions`}
          component={InstitutionsPage}
        />
        <Route
          exact
          path={`${path}prev-winners-2022`}
          component={PrevWinnerPage2022}
        />
        <Route
          exact
          path={`${path}prev-winners-2023`}
          component={PrevWinnerPage2023}
        />
        <Route exact path={`${path}about`} component={AboutUs} />

        {/* <Route exact path={`${path}molem-library`} component={MolemLibrary} />
                    <Route exact path={`${path}molem-library/message`} component={MessagePage} />
                    <Route exact path={`${path}molem-library/all`} component={LibraryAllPage} />
                    <Route exact path={`${path}molem-library/video`} component={VideoPage} /> */}

        <Route
          exact
          path={`${path}smart-khaliji-investor-award`}
          component={AwardPage}
        />
        <Route
          exact
          path={`${path}smart-khaliji-investor-award/thank-you`}
          component={AwardThankYouPage}
        />
        <Route
          exact
          path={`${path}smart-khaliji-investor-award/videography`}
          component={VideographyPage}
        />
        <Route
          exact
          path={`${path}smart-khaliji-investor-award/photography`}
          component={PhotographyPage}
        />
        <Route
          exact
          path={`${path}smart-khaliji-investor-award/drawing`}
          component={DrawingPage}
        />
        <Route
          exact
          path={`${path}khaliji-investor-week`}
          component={InvestorWeek}
        />
        <Route exact path={`${path}policy`} component={Policy} />
        <Route exact path={`${path}FAQs`} component={FAQsPage} />
        <Route
          exact
          path={`${path}ideas-for-the-khaliji-smart-investor-award`}
          component={IdeasToParticipateInTheKhalijiSmartInvestorAwardPage}
        />
      </Switch>
    </BasicLayout>
  );
}
