import ImageDialog from "./ImageDialog";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import RamaSalehAlHajji from "../../../Assets/IdeasForTheKhalijiSmartInvestorAward/RamaSalehAlHajji.jpeg";
// import OmarAlQahtani from "../../../Assets/IdeasForTheKhalijiSmartInvestorAward/OmarAlQahtani.JPEG";
// import MalazMostafaAlHammadi from "../../../Assets/IdeasForTheKhalijiSmartInvestorAward/MalazMostafaAlHammadi.png";
// import NawafAteeqAlMutlaq from "../../../Assets/IdeasForTheKhalijiSmartInvestorAward/NawafAteeqAlMutlaq.jpg";
import { useState } from "react";

export default function PhotographyTrack() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);

  // const imagesSrc = [RamaSalehAlHajji, OmarAlQahtani, MalazMostafaAlHammadi, NawafAteeqAlMutlaq]
  const imagesSrc = [
    "https://lh3.googleusercontent.com/u/1/drive-viewer/AFDK6gNpMN-nwQHav1-Nx5JpKTmmhUTsudfuPfd7QKOz-Sjulj-9LODbv9ExJIsk7ztfMjjqs9Pwp9qOZ2_clnJ24vLrspij=w2880-h2400",
    "https://api.mulimgcc.org/api/participations/files/c01b004a-e948-4d87-9950-74bab0dbb2cc",
    "https://api.mulimgcc.org/api/participations/files/6b15389e-798e-4b92-855d-3d2594748ddf",
  ];

  return (
    <Box
      className="flex flex-col items-center space-y-20 py-20 px-2.5"
      sx={{ backgroundColor: "#f8f8f8" }}
    >
      <Typography variant="h5" fontWeight={600}>
        {t("ideasForTheKhalijiSmartInvestorAward.photographyPath")}
      </Typography>
      <Box className="grid md:grid-cols-3 gap-10">
        {imagesSrc.map((src) => (
          <img
            src={src}
            alt=""
            style={{
              width: "355px",
              height: "400px",
              objectFit: "cover",
            }}
            className="rounded-2xl cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
              setSelectedImg(src);
            }}
          />
        ))}
      </Box>
      <ImageDialog
        selectedImg={selectedImg}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  );
}
