import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TitleBox from 'Components/TitleBox/TitleBox';
import React from 'react';
import { useTranslation } from 'react-i18next';

import WebBanners08 from "Assets/Home/carosoel/InnerPageBanners/AboutBanner.png";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";



const useStyles = makeStyles((theme) => ({
    contactUs: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)',
    },
    contactDetailsLabel: {
        color: "#45A041",
        fontWeight: "normal",
        fontSize: "30px",
        padding: "20px"
    },
    contactDetailsHeading: {
        fontWeight: "bold",
        fontSize: "18px",
        marginBottom: "10px"
    },
    contactDetailsText: {
        color: "#45A041",
        fontSize: "16px",
        padding: "10px",
        border: "1px solid #45A041",
        borderRadius: "10px",
        textTransform: "none"
    },
    pText: {
        marginBottom: "30px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "37px",
        color: "#5D6180",
    }
}))

const Policy = () => {
    const classes = useStyles()
    const { t } = useTranslation();
    const headerData = {
        h1Label: t("policy.policy")
    }
    return (
        <div className={classes.contactUs}>
            <TitleBox data={headerData} imageURL={WebBanners08} h1TextColor="#ffffff" h1LabelColor='#ffffff' />
            <Grid container spacing={3} style={{ padding: "50px 5% 100px", marginBottom: "10vh" }} justifyContent="space-between">
                <Grid item xs={12}>
                    <p className={classes.pText}>{t("policy.text1")}</p>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.pText}>{t("policy.text2")}</p>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.pText}>{t("policy.text3")}</p>
                </Grid>
            </Grid>
        </div>
    );
};

export default Policy;
