import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SafeHTML from "./SafeHTML/SafeHTML";
import { useTranslation } from "react-i18next";
import { List, ListItem } from "@mui/material";

const ExpandMoreIcon = () => (
  <svg
    style={{
      marginRight: "8px",
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="28.481"
    height="28.481"
    viewBox="0 0 28.481 28.481"
  >
    <path
      d="M63.443,126.853V100L50.017,113.426Z"
      transform="translate(-106.078 -25.85) rotate(-45)"
      fill="rgb(159 208 155)"
    ></path>
  </svg>
);

export default function AccordionWrap({ items, openAll = false }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (items[3].id !== "information_sources") {
    items.splice(3, 0, {
      id: "information_sources",
    });
  }

  return items.map((item, index) => {
    let panel = `panel${index + 1}`;

    if (item?.id === "information_sources") {
      return (
        <Accordion
          key={panel}
          expanded={expanded === panel || openAll}
          onChange={handleChange(panel)}
          style={{
            boxShadow: "none",
            margin: "0px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              flexDirection: "row-reverse",
              borderBottom: "1px solid #E5E5E5",
            }}
          >
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                color: "black",
                marginBottom: "0",
              }}
              width="100%"
              variant="h6"
              component={"h6"}
              padding="8px 16px"
            >
              {t("footer.informationSources")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="py-5 px-9">
            <Typography>{t("footer.informationSourcesHeader")}</Typography>
            <List sx={{ listStyle: "outside" }} className="px-5">
              <ListItem sx={{ display: "list-item" }} className="p-0">
                <a
                  href="https://si.org.sa/"
                  style={{
                    textDecoration: "underline",
                  }}
                  className="hover:text-gray-900"
                  target="_blank"
                >
                  {t("footer.smartInvestor")}
                </a>
              </ListItem>
              <ListItem sx={{ display: "list-item" }} className="p-0">
                <a
                  href="https://thameen.org.sa/"
                  style={{
                    textDecoration: "underline",
                  }}
                  className="hover:text-gray-900"
                  target="_blank"
                >
                  {t("footer.thamin")}
                </a>
              </ListItem>
              <ListItem sx={{ display: "list-item" }} className="p-0">
                <a
                  href="https://bahrainbourse.com/en/Investors/InvestorAwareness/Pages/Awareness-Videos.aspx"
                  style={{
                    textDecoration: "underline",
                  }}
                  className="hover:text-gray-900"
                  target="_blank"
                >
                  {t("footer.bahrain_bourse_link")}
                </a>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (
      <Accordion
        key={panel}
        expanded={expanded === panel || openAll}
        onChange={handleChange(panel)}
        style={{
          boxShadow: "none",
          margin: "0px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            flexDirection: "row-reverse",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "24px",
              color: "black",
              marginBottom: "0",
            }}
            width="100%"
            variant="h6"
            component={"h6"}
            padding="8px 16px"
          >
            {item?.heading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SafeHTML
            variant="body1"
            component={"div"}
            style={{
              margin: "20px 30px",
            }}
          >
            {item?.description}
          </SafeHTML>
        </AccordionDetails>
      </Accordion>
    );
  });
}
