import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import footerLogo from "Assets/footerLogo.png";
import { color1, color2, color4, color5, color1Hover } from "Pages/Constant";
import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import HeroBox from "Components/TitleBox/HeroBox";
import { WINNERS_PAGE } from "Constants/QueriesKeys";
import Carousel, { consts } from "react-elastic-carousel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPlayer from "react-player";

import VideoIconColored from "Assets/awards/VideoIconColored.png";
import PhotoIconColored from "Assets/awards/PhotoIconColored.png";
import PencilIconColored from "Assets/awards/PencilIconColored.png";
import WritingIconColored from "Assets/awards/writing.png";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: color1,
  },
  h1Label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "72px",
  },
  h1Text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "16px",
  },
  contactDetailsHeading: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "10px",
  },
  contactDetailsText: {
    fontSize: "18px",
    textTransform: "none",
    textAlign: "justify",
    marginBottom: "20px",
  },
  countriesMemberHeading: {
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "67px",
    color: color2,
    textAlign: "center",
  },
  countriesMemberText: {
    color: color4,
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    maxWidth: "250px",
  },
  categoryInActive: {
    cursor: "pointer",
    padding: "15px",
    color: color5,
    fontSize: "24px",
    fontWeight: "bold",
  },
  categoryActive: {
    padding: "15px",
    color: "#fff !important",
    fontSize: "30px",
    backgroundColor: color2,
    fontWeight: "bold",
  },
  clickableText: {
    cursor: "pointer",
    padding: "10px",
    textAlign: "justify",
    color: "#5EB3E4",
    fontSize: "15px",
    "&:hover": {
      color: color1Hover,
    },
  },
  nonClickableText: {
    padding: "10px",
    fontSize: "15px",
    color: "#5EB3E4",
    lineHeight: "1.5rem",
  },
  card: {
    border: `1px solid ${color5}`,
    borderRadius: "10px",
  },
  tabPanel: {
    padding: "20px",
  },
  TabItem: {
    fontSize: "20px",
    fontWeight: "normal",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  websiteLogoMain: {
    // paddingRight: "20px",
    borderRight: "1px solid rgba(0,0,0,0.15)",
    [theme.breakpoints.down("md")]: {
      paddingRight: "0px",
      borderRight: "none",
    },
  },
  websiteLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px ,rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    },
  },
}));

const PREV_WINNERS_VIDEO = [
  {
    id: 1,
    type: "video",
    path: "https://youtu.be/e9sRUoyB4hc",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/fbbe6328-c855-4565-a58c-faee15658f60",
  },
  {
    id: 2,
    type: "video",
    path: "https://youtu.be/5Dzutm4J9d8",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/4b54c4ce-c688-4172-9c69-7d8ffa9b5a7a",
  },
  {
    id: 3,
    type: "video",
    path: "https://youtu.be/8DYhDH-O0P0",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/75e05cdb-6271-4fc0-91b6-42c3872e4c20",
  },
  {
    id: 4,
    type: "video",
    path: "https://youtu.be/cWC7kBQJ6gE",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/fbbe6328-c855-4565-a58c-faee15658f60",
  },
  {
    id: 5,
    type: "video",
    path: "https://youtu.be/z_DCnq1GDUE",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/4b54c4ce-c688-4172-9c69-7d8ffa9b5a7a",
  },
  {
    id: 6,
    type: "video",
    path: "https://youtu.be/fF19aUrgSRY",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/75e05cdb-6271-4fc0-91b6-42c3872e4c20",
  },
  {
    id: 7,
    type: "video",
    path: "https://youtu.be/AGN9y9PUJko",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/fbbe6328-c855-4565-a58c-faee15658f60",
  },
  {
    id: 8,
    type: "video",
    path: "https://youtu.be/55GaGsjP5ps",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/4b54c4ce-c688-4172-9c69-7d8ffa9b5a7a",
  },
  {
    id: 9,
    type: "video",
    path: "https://youtu.be/d1pkOo7Ey-M",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/75e05cdb-6271-4fc0-91b6-42c3872e4c20",
  },
];
const PREV_WINNERS_DRAWING = [
  {
    id: 1,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/914007fc-0e86-4c4b-96f5-c558b9657ad2",
  },
  {
    id: 2,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/8b45f934-a441-47e6-983e-daee1a54e2d0",
  },
  {
    id: 3,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/22b59f4b-7451-418a-8330-95f3769bf466",
  },
  {
    id: 4,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/e0a3c14a-543e-40a0-9b15-46b0e0fd3a92",
  },
  {
    id: 5,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/daf55206-3875-478d-bf99-916ac7029a70",
  },
  {
    id: 6,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/17a6a2af-7e30-4caa-a760-0d6741c64795",
  },
  {
    id: 7,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/fca92d88-cce4-4440-a955-657bb017fe74",
  },
  {
    id: 8,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/b4f6a6a2-8c3a-4e53-908a-8ed3c962cccd",
  },
  {
    id: 9,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/0d33234e-a61e-4968-b0ae-c08f8bbac310",
  },
];

const PREV_WINNERS_WRITING = [
  {
    id: 1,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/cd1e540e-b60e-4303-b0e3-f2f300817886",
  },
  {
    id: 2,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/c3bf4de5-6255-49e3-8b6e-b3fdbb71760e",
  },
  {
    id: 3,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/2ae5ed70-edb4-4950-bb78-2cdd80af96f1",
  },
  {
    id: 4,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/9ddb6dce-b4c4-4d64-b0e7-2f2327da7352",
  },
  {
    id: 5,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/5f1d60ce-85d7-4185-a33c-24e215b66e2b",
  },
  {
    id: 6,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/0119de5a-93f3-435b-81db-400c8160d454",
  },
  {
    id: 7,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/3dcd2ef5-cfe5-4c81-b9e4-dbf39785abc0",
  },
  {
    id: 8,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/357adfed-ed27-4347-848c-05c2d219b9f1",
  },
  {
    id: 9,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/ac33c7ec-a42f-4725-8943-fe1e7a525c2f",
  },
];

const PREV_WINNERS_IMAGE = [
  {
    id: 1,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/8b972b8d-3d53-4968-86bb-1889c1494eee",
  },
  {
    id: 2,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/2191387c-4bb7-4c6d-a86f-31f24ce0f9bd",
  },
  {
    id: 3,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/cd033f3f-9f30-4926-b4b8-bece4d37da81",
  },
  {
    id: 4,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/bb53517e-5923-4040-9233-ae7a6006f2f9",
  },
  {
    id: 5,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/2b5e42bb-43b8-4cb7-bf53-b836a0843ffd",
  },
  {
    id: 6,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/46567606-7a59-4b63-b502-59c3691f3341",
  },
  {
    id: 7,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/fc783117-69c0-4976-99a2-1edb4b914e68",
  },
  {
    id: 8,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/81e60db1-8e64-4a61-a947-1b46fcd94f05",
  },
  {
    id: 9,
    type: "image",
    path: "https://api.mulimgcc.org/api/participations/files/1f1eda1b-861d-4db5-8308-89a2c92dbf32",
  },
  // {
  //   id: 7,
  //   type: "video",
  //   path: "https://youtu.be/f3hszjKKg7w",
  //   tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/fbbe6328-c855-4565-a58c-faee15658f60",
  // },
  // {
  //   id: 8,
  //   type: "video",
  //   path: "https://youtu.be/ZU_CJQp4-d8",
  //   tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/4b54c4ce-c688-4172-9c69-7d8ffa9b5a7a",
  // },
  // {
  //   id: 9,
  //   type: "video",
  //   path: "https://youtu.be/D1B1xVQ9JZY",
  //   tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/75e05cdb-6271-4fc0-91b6-42c3872e4c20",
  // },
];

export default function PrevWinnerPage2023() {
  const classes = useStyles();

  const playerRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlay = (index) => {
    setPlayingIndex(index);

    // Pause other players
    playerRefs.current.forEach((player, idx) => {
      if (idx !== index && player) {
        player.seekTo(0);
        player.getInternalPlayer().pauseVideo();
      }
    });
  };

  const [heroBoxDetails, setHeroBoxDetails] = useState();
  const aboutPageQuery = useQuery([WINNERS_PAGE], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        data,
        // setAboutArray(data?.data?.aboutPage?.aboutCardDetails),
        setHeroBoxDetails(data?.data?.prevWinners2023Page?.headerDetails)
      );
    })
  );

  return (
    <div className={classes.contactUs}>
      <HeroBox heroBoxDetails={heroBoxDetails} textMaxWidth="500px" />
      <Box
        style={{
          backgroundColor: "#F8F8F8",
        }}
      >
        <Grid
          maxWidth={"xl"}
          container
          margin={"0 auto"}
          alignItems="center"
          justifyContent="center"
          style={{
            padding: "5%",
            marginBottom: "10vh",
          }}
        >
          <Grid item xs={12}>
            <Stack gap={14}>
              <Typography variant="h3" textAlign={"center"}>
                الحفل الختامي
              </Typography>
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=j3fTbP5oNBQ"}
                width="100%"
                height="600px"
                controls={true}
                playing={
                  playingIndex === "https://www.youtube.com/watch?v=j3fTbP5oNBQ"
                }
                onPlay={() =>
                  handlePlay("https://www.youtube.com/watch?v=j3fTbP5oNBQ")
                }
                ref={(player) => {
                  playerRefs.current[
                    "https://www.youtube.com/watch?v=j3fTbP5oNBQ"
                  ] = player;
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box
        container
        maxWidth={"xl"}
        // alignItems="center"
        style={{
          margin: "0 auto",

          marginTop: "150px",
          marginBottom: "150px",
        }}
      >
        {/* VIDEO */}
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={VideoIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار الفيديو </Typography>
          </Box>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الأفراد )"}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              marginY: 4,
            }}
          >
            {[
              PREV_WINNERS_VIDEO[6],
              PREV_WINNERS_VIDEO[7],
              PREV_WINNERS_VIDEO[8],
            ].map((itemVideo) => (
              <Grid key={itemVideo} item xs={12} md={4}>
                <Box
                  // component={"img"}
                  // src={itemVideo.tag}
                  sx={{
                    width: "100%",
                    // paddingTop: "56.25%",
                    // position: "relative",
                  }}
                >
                  <Box
                    component={"img"}
                    width={100}
                    src={itemVideo.tag}
                    sx={{
                      position: "relative",
                      bottom: "-85px",
                      marginTop: "-85px",
                    }}
                  />
                  <ReactPlayer
                    url={itemVideo.path}
                    width="100%"
                    height="300px"
                    controls={true}
                    playing={playingIndex === itemVideo.path}
                    onPlay={() => handlePlay(itemVideo.path)}
                    ref={(player) => {
                      playerRefs.current[itemVideo.path] = player;
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الجامعات )"}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              marginY: 4,
            }}
          >
            {[
              PREV_WINNERS_VIDEO[3],
              PREV_WINNERS_VIDEO[4],
              PREV_WINNERS_VIDEO[5],
            ].map((itemVideo) => (
              <Grid key={itemVideo} item xs={12} md={4}>
                <Box
                  // component={"img"}
                  // src={itemVideo.tag}
                  sx={{
                    width: "100%",
                    // paddingTop: "56.25%",
                    // position: "relative",
                  }}
                >
                  <Box
                    component={"img"}
                    width={100}
                    src={itemVideo.tag}
                    sx={{
                      position: "relative",
                      bottom: "-85px",
                      marginTop: "-85px",
                    }}
                  />
                  <ReactPlayer
                    url={itemVideo.path}
                    width="100%"
                    height="300px"
                    controls={true}
                    playing={playingIndex === itemVideo.path}
                    onPlay={() => handlePlay(itemVideo.path)}
                    ref={(player) => {
                      playerRefs.current[itemVideo.path] = player;
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( المدارس )"}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              marginY: 4,
            }}
          >
            {[
              PREV_WINNERS_VIDEO[0],
              PREV_WINNERS_VIDEO[1],
              PREV_WINNERS_VIDEO[2],
            ].map((itemVideo) => (
              <Grid key={itemVideo} item xs={12} md={4}>
                <Box
                  // component={"img"}
                  // src={itemVideo.tag}
                  sx={{
                    width: "100%",
                    // paddingTop: "56.25%",
                    // position: "relative",
                  }}
                >
                  <Box
                    component={"img"}
                    width={100}
                    src={itemVideo.tag}
                    sx={{
                      position: "relative",
                      bottom: "-85px",
                      marginTop: "-85px",
                    }}
                  />
                  <ReactPlayer
                    url={itemVideo.path}
                    width="100%"
                    height="300px"
                    controls={true}
                    playing={playingIndex === itemVideo.path}
                    onPlay={() => handlePlay(itemVideo.path)}
                    ref={(player) => {
                      playerRefs.current[itemVideo.path] = player;
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* DRAWING */}
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={PencilIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار الرسم </Typography>
          </Box>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الأفراد )"}
          </Typography>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_DRAWING[6],
              PREV_WINNERS_DRAWING[7],
              PREV_WINNERS_DRAWING[8],
            ].map((itemDrawing) => (
              <Grid key={itemDrawing.path} item xs={12} md={4}>
                <Box
                  component={"img"}
                  src={itemDrawing.path}
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الجامعات )"}
          </Typography>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_DRAWING[3],
              PREV_WINNERS_DRAWING[4],
              PREV_WINNERS_DRAWING[5],
            ].map((itemDrawing) => (
              <Grid key={itemDrawing.path} item xs={12} md={4}>
                <Box
                  component={"img"}
                  src={itemDrawing.path}
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
           <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( المدارس )"}
          </Typography>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_DRAWING[0],
              PREV_WINNERS_DRAWING[1],
              PREV_WINNERS_DRAWING[2],
            ].map((itemDrawing) => (
              <Grid key={itemDrawing.path} item xs={12} md={4}>
                <Box
                  component={"img"}
                  src={itemDrawing.path}
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* IMAGES */}
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={PhotoIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار التصوير </Typography>
          </Box>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الأفراد )"}
          </Typography>
          <Grid container spacing={4} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_IMAGE[6],
              PREV_WINNERS_IMAGE[7],
              PREV_WINNERS_IMAGE[8],
            ].map((itemPhoto) => (
              <Grid key={itemPhoto.path} item xs={12} md={4}>
                <Box
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                  component={"img"}
                  src={itemPhoto.path}
                ></Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الجامعات )"}
          </Typography>
          <Grid container spacing={4} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_IMAGE[3],
              PREV_WINNERS_IMAGE[4],
              PREV_WINNERS_IMAGE[5],
            ].map((itemPhoto) => (
              <Grid key={itemPhoto.path} item xs={12} md={4}>
                <Box
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                  component={"img"}
                  src={itemPhoto.path}
                ></Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( المدارس )"}
          </Typography>
          <Grid container spacing={4} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_IMAGE[0],
              PREV_WINNERS_IMAGE[1],
              PREV_WINNERS_IMAGE[2],
            ].map((itemPhoto) => (
              <Grid key={itemPhoto.path} item xs={12} md={4}>
                <Box
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                  component={"img"}
                  src={itemPhoto.path}
                ></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* WRITING */}
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={WritingIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار الكتابة </Typography>
          </Box>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الأفراد )"}
          </Typography>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_WRITING[6],
              PREV_WINNERS_WRITING[7],
              PREV_WINNERS_WRITING[8],
            ].map((itemDrawing) => (
              <Grid key={itemDrawing.path} item xs={12} md={4}>
                <Box
                  component={"img"}
                  src={itemDrawing.path}
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( الجامعات )"}
          </Typography>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_WRITING[3],
              PREV_WINNERS_WRITING[4],
              PREV_WINNERS_WRITING[5],
            ].map((itemDrawing) => (
              <Grid key={itemDrawing.path} item xs={12} md={4}>
                <Box
                  component={"img"}
                  src={itemDrawing.path}
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
           <Typography variant="h3" mt={10} textAlign={"center"}>
            {"( المدارس )"}
          </Typography>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[
              PREV_WINNERS_WRITING[0],
              PREV_WINNERS_WRITING[1],
              PREV_WINNERS_WRITING[2],
            ].map((itemDrawing) => (
              <Grid key={itemDrawing.path} item xs={12} md={4}>
                <Box
                  component={"img"}
                  src={itemDrawing.path}
                  sx={{
                    transition: "transform 0.25s ease-in-out",
                    borderRadius: 2,
                    boxShadow: 1,
                    "&:hover": {
                      transform: "scale3d(1.05, 1.05, 1)",
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                    },
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

function MyArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />;

  return (
    <Button
      onClick={onClick}
      // className={classes.bannerButton}
      disabled={isEdge}
      style={{ minWidth: "30px", padding: "0" }}
    >
      <span>{pointer}</span>
    </Button>
  );
}

function MyPagination({ pages, activePage }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
      {pages.map((item, index) => {
        return (
          <Box
            key={item}
            sx={{
              width: 12,
              height: 12,
              borderRadius: 999,
              backgroundColor: (theme) =>
                index === activePage
                  ? theme.palette.success.main
                  : theme.palette.grey[400],
              margin: 2,
            }}
          />
        );
      })}
    </Box>
  );
}
