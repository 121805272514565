import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./MainEntry";
import reportWebVitals from "./reportWebVitals";
import "Utils/I18n";
import { QueryClientProvider, QueryClient } from "react-query";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PWMRPL7",
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
