import HeroImg from "../../../Assets/IdeasForTheKhalijiSmartInvestorAward/Hero.jpeg";

export default function Hero() {
  return (
    <img
      src={
        "https://thameen.org.sa/wp-content/uploads/2022/12/Mulem-Brand-Web-Banners-4267x1917-01-1.jpg"
      }
      alt=""
      className="w-full"
    />
  );
}
