import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactCardCarousel from "react-card-carousel";

class MyCarousel extends Component {
  static get CONTAINER_STYLE() {
    return {
      position: "relative",
      height: "400px",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "middle"
    };
  }

  static get CARD_STYLE() {
    return {
      padding: "0px",
      margin: "0px",
      boxShadow: "0px 4px 40px rgba(43, 89, 255, 0.08)",
      borderRadius: "10px",   
    };
  }

  render() {
    return (
      <div style={MyCarousel.CONTAINER_STYLE}>
        <ReactCardCarousel initial_index={1} disable_box_shadow autoplay={true} spread="wide">
            {this.props.children}
        </ReactCardCarousel>
      </div>
    );
  }
}

export default MyCarousel;
