import { Button, Grid, Card, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from "@mui/lab";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import styles1 from "./FlipCard/Flipcard.module.css";

const useStyles = makeStyles((theme) => ({
  newRelease: {
    // height: "700px",
  },
  btn_primary: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "28px",
    lineHeight: "180%",
    textAlign: "center",
    color: "#99A7AC",
    borderRadius: "0px",
    borderColor: "#B4D8F1",
    padding: "0px 28px",
    border: "0px solid rgb(180, 216, 241)",
    borderBottomWidth: "3px",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  btn_primary_selected: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "28px",
    lineHeight: "180%",
    padding: "0px 28px",
    textAlign: "center",
    color: "#042a30",
    background: "#f9f8f9",
    borderRadius: "0px",
    border: "0px solid #5eb3e4",
    borderBottomWidth: "3px",
    "&:hover": {
      background: "#f9f8f9",
    },
  },
}));

const RewardMoney = () => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const [newReleaseSelection, setNewReleaseSelection] = useState("1");

  const videographyArray = [
    { position: t("award.firstAward"), rewardAmount: "50,000" },
    { position: t("award.secondAward"), rewardAmount: "40,000" },
    { position: t("award.thirdAward"), rewardAmount: "30,000" },
  ];

  const photographyArray = [
    { position: t("award.firstAward"), rewardAmount: "40,000" },
    { position: t("award.secondAward"), rewardAmount: "30,000" },
    { position: t("award.thirdAward"), rewardAmount: "20,000" },
  ];

  const drawingArray = [
    { position: t("award.firstAward"), rewardAmount: "30,000" },
    { position: t("award.secondAward"), rewardAmount: "20,000" },
    { position: t("award.thirdAward"), rewardAmount: "15,000" },
  ];

  const rewardArrayTitleList = [
    {
      title: `${t("award.videography")}`,
    },
    {
      title: `${t("award.photographer")}`,
    },
    {
      title: `${t("award.drawing")}`,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h3">{t("award.award")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{marginTop:"50px" }}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h4" textAlign={"center"}>
            "جوائز قيمة لـ 44 فائز بقيمة تتجاوز الـ 1 مليون ريال سعودي"
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RewardMoney;
