import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import MaskGroup from "Assets/awards/VideographyImage.png";
import twiterIcon from "Assets/commingSoon/twitterIcon.svg";
import instagramIcon from "Assets/commingSoon/instagramIcon.svg";
import youtubeIcon from "Assets/commingSoon/youtubeIcon.svg";
import { useTranslation } from "react-i18next";
import HelmetWrapper from "Components/HelmetWrapper/HelmetWrapper";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: "rgba(0,0,0,1)",
  },
  h1Label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "72px",
    color: "white",
  },
  h1Text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "16px",
  },
  headingContainer: {
    minHeight: "50vh",
    backgroundImage: `url(${MaskGroup})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "bottom",
  },
  commingSoon: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "70px",
    lineHeight: "58px",
    marginBottom: "10px",
  },
  text1: {
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    marginBottom: "50px",
  },
}));

const VideographyPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const linksArray = [
    {
      icon: youtubeIcon,
      path: "https://www.youtube.com/channel/UChrdz7OmRR7OaOZ3Dl_WkWA",
    },
    {
      icon: instagramIcon,
      path: "https://instagram.com/mulimgcc",
    },
    {
      icon: twiterIcon,
      path: "https://twitter.com/mulimgcc",
    },
  ];

  var showDetails = {
    title: t("award.videography"),
  };
  return (
    <div className={classes.contactUs}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headingContainer}
      >
        <Grid item>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <h1 className={classes.h1Label}>{showDetails.title}</h1>
            </Grid>
            <Grid item xs={12} style={{ maxWidth: "420px" }}>
              <p className={classes.h1Text}>{showDetails.text1}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{ padding: "0 5%", marginBottom: "10vh", marginTop: "10vh" }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <p
                className={classes.commingSoon}
                style={{ textAlign: "center", marginBottom: "40px" }}
              >
                {t("common.comingSoon")}
              </p>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <p className={classes.text1} style={{ textAlign: "center", maxWidth: "420px" }}>{t("common.text1")}</p>
                        </Grid>
                    </Grid>
                </Grid> */}
        {/* <Grid item xs={12}>
                    <Grid container spacing={5} justifyContent='center'>
                        {linksArray?.map((item) => {
                            return (
                                <Grid item>
                                    <a target="blank" href={item.path}>
                                        <img src={item.icon} alt='' style={{ width: "40px", cursor: "pointer" }} />
                                    </a>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid> */}
      </Grid>
    </div>
  );
};

export default VideographyPage;
