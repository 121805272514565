import ReactPlayer from "react-player";

function Player({ url, id, onPlay, currentId }) {
  return (
    <ReactPlayer
      playing={currentId === id}
      playsinline={true}
      controls={true}
      url={url}
      width="355px"
      height="350px"
      onPlay={() => onPlay({ id })}
    />
  );
}

export default Player;
