import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import footerLogo from "Assets/footerLogo.png";
import { color1, color2, color4, color5, color1Hover } from "Pages/Constant";
import { useQuery } from "react-query";
import { http } from "Utils/Http/Http";
import HeroBox from "Components/TitleBox/HeroBox";
import { WINNERS_PAGE } from "Constants/QueriesKeys";
import Carousel, { consts } from "react-elastic-carousel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPlayer from "react-player";

import VideoIconColored from "Assets/awards/VideoIconColored.png";
import PhotoIconColored from "Assets/awards/PhotoIconColored.png";
import PencilIconColored from "Assets/awards/PencilIconColored.png";
import WritingIconColored from "Assets/awards/writing.png";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: color1,
  },
  h1Label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "72px",
  },
  h1Text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "16px",
  },
  contactDetailsHeading: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "10px",
  },
  contactDetailsText: {
    fontSize: "18px",
    textTransform: "none",
    textAlign: "justify",
    marginBottom: "20px",
  },
  countriesMemberHeading: {
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "67px",
    color: color2,
    textAlign: "center",
  },
  countriesMemberText: {
    color: color4,
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    maxWidth: "250px",
  },
  categoryInActive: {
    cursor: "pointer",
    padding: "15px",
    color: color5,
    fontSize: "24px",
    fontWeight: "bold",
  },
  categoryActive: {
    padding: "15px",
    color: "#fff !important",
    fontSize: "30px",
    backgroundColor: color2,
    fontWeight: "bold",
  },
  clickableText: {
    cursor: "pointer",
    padding: "10px",
    textAlign: "justify",
    color: "#5EB3E4",
    fontSize: "15px",
    "&:hover": {
      color: color1Hover,
    },
  },
  nonClickableText: {
    padding: "10px",
    fontSize: "15px",
    color: "#5EB3E4",
    lineHeight: "1.5rem",
  },
  card: {
    border: `1px solid ${color5}`,
    borderRadius: "10px",
  },
  tabPanel: {
    padding: "20px",
  },
  TabItem: {
    fontSize: "20px",
    fontWeight: "normal",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  websiteLogoMain: {
    // paddingRight: "20px",
    borderRight: "1px solid rgba(0,0,0,0.15)",
    [theme.breakpoints.down("md")]: {
      paddingRight: "0px",
      borderRight: "none",
    },
  },
  websiteLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px ,rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    },
  },
}));

const PREV_WINNERS = [
  {
    id: 1,
    type: "image",
    path: "https://api.mulimgcc.org/api/v1/helpers/images/files/139df85c-1048-4529-af9c-ee47912bf85d",
  },
  {
    id: 2,
    type: "image",
    path: "https://api.mulimgcc.org/api/v1/helpers/images/files/f9500658-8811-4d59-a75e-af57721e6c6f",
  },
  {
    id: 3,
    type: "image",
    path: "https://api.mulimgcc.org/api/v1/helpers/images/files/21c30ac9-f95d-4173-9db9-383e5830e7bd",
  },
  {
    id: 4,
    type: "image",
    path: "https://api.mulimgcc.org/api/v1/helpers/images/files/849f0b54-7be4-4a39-8599-327f1e01e43c",
  },
  {
    id: 5,
    type: "image",
    path: "https://api.mulimgcc.org/api/v1/helpers/images/files/feae990c-48cb-41e2-987d-1a485df2b55e",
  },
  {
    id: 6,
    type: "image",
    path: "https://api.mulimgcc.org/api/v1/helpers/images/files/39ddb652-8895-46bb-aa94-f5930b30ef43",
  },
  {
    id: 7,
    type: "video",
    path: "https://youtu.be/f3hszjKKg7w",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/fbbe6328-c855-4565-a58c-faee15658f60",
  },
  {
    id: 8,
    type: "video",
    path: "https://youtu.be/ZU_CJQp4-d8",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/4b54c4ce-c688-4172-9c69-7d8ffa9b5a7a",
  },
  {
    id: 9,
    type: "video",
    path: "https://youtu.be/D1B1xVQ9JZY",
    tag: "https://api.mulimgcc.org/api/v1/helpers/images/files/75e05cdb-6271-4fc0-91b6-42c3872e4c20",
  },
];

export default function PrevWinnerPage2022() {
  const classes = useStyles();
  const { t } = useTranslation();

  const playerRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlay = (index) => {
    setPlayingIndex(index);

    // Pause other players
    playerRefs.current.forEach((player, idx) => {
      if (idx !== index && player) {
        player.seekTo(0);
        player.getInternalPlayer().pauseVideo();
      }
    });
  };

  const [heroBoxDetails, setHeroBoxDetails] = useState();

  const aboutPageQuery = useQuery([WINNERS_PAGE], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        data,
        // setAboutArray(data?.data?.aboutPage?.aboutCardDetails),
        setHeroBoxDetails(data?.data?.prevWinnersPage?.headerDetails)
      );
    })
  );

  return (
    <div className={classes.contactUs}>
      <HeroBox heroBoxDetails={heroBoxDetails} textMaxWidth="500px" />
      <Box
        style={{
          backgroundColor: "#F8F8F8",
        }}
      >
        <Grid
          maxWidth={"xl"}
          container
          margin={"0 auto"}
          alignItems="center"
          justifyContent="center"
          style={{
            padding: "5%",
            marginBottom: "10vh",
          }}
        >
          <Grid item xs={12}>
            <Stack gap={14}>
              <Typography variant="h3" textAlign={"center"}>
                الحفل الختامي
              </Typography>
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=2geAT6fGqUA"}
                width="100%"
                height="600px"
                controls={true}
                playing={
                  playingIndex === "https://www.youtube.com/watch?v=2geAT6fGqUA"
                }
                onPlay={() =>
                  handlePlay("https://www.youtube.com/watch?v=2geAT6fGqUA")
                }
                ref={(player) => {
                  playerRefs.current[
                    "https://www.youtube.com/watch?v=2geAT6fGqUA"
                  ] = player;
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box
        container
        maxWidth={"xl"}
        // alignItems="center"
        style={{
          margin: "0 auto",

          marginTop: "150px",
          marginBottom: "150px",
        }}
      >
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={VideoIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار الفيديو </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              marginY: 4,
            }}
          >
            {[PREV_WINNERS[6], PREV_WINNERS[7], PREV_WINNERS[8]].map(
              (itemVideo) => (
                <Grid key={itemVideo} item xs={12} md={4}>
                  <Box
                    // component={"img"}
                    // src={itemVideo.tag}
                    sx={{
                      width: "100%",
                      // paddingTop: "56.25%",
                      // position: "relative",
                    }}
                  >
                    <Box
                      component={"img"}
                      width={100}
                      src={itemVideo.tag}
                      sx={{
                        position: "relative",
                        bottom: "-85px",
                        marginTop: "-85px",
                      }}
                    />
                    <ReactPlayer
                      url={itemVideo.path}
                      width="100%"
                      height="300px"
                      controls={true}
                      playing={playingIndex === itemVideo.path}
                      onPlay={() => handlePlay(itemVideo.path)}
                      ref={(player) => {
                        playerRefs.current[itemVideo.path] = player;
                      }}
                    />
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={PencilIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار الرسم </Typography>
          </Box>
          <Grid container spacing={2} sx={{ marginY: 4 }}>
            {[PREV_WINNERS[3], PREV_WINNERS[4], PREV_WINNERS[5]].map(
              (itemDrawing) => (
                <Grid key={itemDrawing.path} item xs={12} md={4}>
                  <Box
                    component={"img"}
                    src={itemDrawing.path}
                    sx={{
                      transition: "transform 0.25s ease-in-out",
                      borderRadius: 2,
                      boxShadow: 1,
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 1)",
                        boxShadow: 3,
                        borderRadius: 4,
                        backgroundColor: "#fff",
                      },
                    }}
                  ></Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <Box sx={{ marginY: 20 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box
              component={"img"}
              src={PhotoIconColored}
              width={"auto"}
              maxHeight={60}
            />
            <Typography variant="h3"> مسار التصوير </Typography>
          </Box>
          <Grid container spacing={4} sx={{ marginY: 4 }}>
            {[PREV_WINNERS[0], PREV_WINNERS[1], PREV_WINNERS[2]].map(
              (itemPhoto) => (
                <Grid key={itemPhoto.path} item xs={12} md={4}>
                  <Box
                    sx={{
                      transition: "transform 0.25s ease-in-out",
                      borderRadius: 2,
                      boxShadow: 1,
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 1)",
                        boxShadow: 3,
                        borderRadius: 4,
                        backgroundColor: "#fff",
                      },
                    }}
                    component={"img"}
                    src={itemPhoto.path}
                  ></Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

function MyArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />;

  return (
    <Button
      onClick={onClick}
      // className={classes.bannerButton}
      disabled={isEdge}
      style={{ minWidth: "30px", padding: "0" }}
    >
      <span>{pointer}</span>
    </Button>
  );
}

function MyPagination({ pages, activePage }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
      {pages.map((item, index) => {
        return (
          <Box
            key={item}
            sx={{
              width: 12,
              height: 12,
              borderRadius: 999,
              backgroundColor: (theme) =>
                index === activePage
                  ? theme.palette.success.main
                  : theme.palette.grey[400],
              margin: 2,
            }}
          />
        );
      })}
    </Box>
  );
}
