import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Dialog } from '@mui/material';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  card: {
    boxShadow: '1px 2px 6px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    margin: '10px',
  },
  titleRight: {
    fontSize: '16px',
    color: 'rgba(14, 109, 167, 1)',
  },
  title: {
    fontSize: '23px',
    color: '#45A041',
    fontWeight: '500',
  },
  btn_add: {
    backgroundColor: 'rgba(62, 199, 230, 1)',
    fontSize: '12px',
    color: 'rgba(255, 255, 255, 1)',
    '&:hover': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgba(62, 199, 230, 1)',
    },
  },
  btn_more: {
    fontSize: '12px',
    color: 'rgba(113, 205, 226, 1) !important',
    textTransform: 'capitalize',
    '&:hover': {
      color: 'rgba(113, 205, 226, 1)',
    },
  },
  formLabel: {
    fontStyle: 'normal',
    // fontWeight: "bold",
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#252F40',
  },
  formSubLabel: {
    fontStyle: 'normal',
    // fontWeight: "bold",
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#A0A0A0',
  },
}));


const AwardCommonModal = ({ open, handleClose, children, title, maxWidth="md" }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      fullWidth
      maxWidth={maxWidth}
    >
      <Fade in={open}>
                <Box >
                    <Grid container justifyContent='space-between' flexWrap="nowrap" style={{
                        padding: "8px 16px",
                    }}>
                        <Grid item>
                            <h3 className={classes.title}>{title}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseModal}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <Grid container justifyContent='space-between' alignItems="center" style={{
                        padding: "16px 16px 32px 16px",
                    }} >
                      {children}
                    </Grid>
                </Box>
            </Fade>
    </Dialog>
  );
};

export default AwardCommonModal;
