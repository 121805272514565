import ScrollToTop from "Components/ScrollToTop/ScrollToTop";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

export default function BasicLayout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <ScrollToTop />
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  );
}
