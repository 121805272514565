import React from 'react';
import EditorInstance from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import { LocalStorage } from 'Utils/LocalStorage';
const storage = new LocalStorage();

const userToken = storage.get('userToken') || {};

const editorConfiguration = {
    fontSize:{
        options:[15,18,20,22,24,28,32]
    },
    heading:{
        options: [
            { model: 'paragraph',view: 'h1', title: 'Paragraph'},
            { model: 'heading1', view: 'h1', title: 'Heading 1'},
            { model: 'heading2', view: 'h2', title: 'Heading 2'},
            { model: 'heading3', view: 'h3', title: 'Heading 3'},
        ]
    },
    toolbar: {
        items: [
            'alignment',
            'blockQuote',
            'bold',
            'selectAll',
            'undo',
            'redo',
            'fontSize',
            "fontColor",
            'FontBackgroundColor',
            'heading',
            'horizontalLine',
            'imageTextAlternative',
            'imageUpload',
            'indent',
            'outdent',
            'italic',
            'link',
            'numberedList',
            'bulletedList',
            'mediaEmbed',
            'insertTable',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
        ],
        shouldNotGroupWhenFull: true,
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: `${process.env.REACT_APP_BASE_URL}/helpers/upload-file`,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            'x-auth-token': userToken,
        }
    }
};

export default function Editor({ error, label, value, onBlur, onChange, helperText, ...otherProps }) {
    return (
        <FormControl error={Boolean(error)} fullWidth>
            <FormLabel className="mb-3">{label}</FormLabel>
            <CKEditor
                editor={EditorInstance}
                config={editorConfiguration}
                data={value}
                onChange={onChange}
                onBlur={onBlur}
                {...otherProps}
            />
            <FormHelperText style={{ color: '#d32f2f' }}>{helperText}</FormHelperText>
        </FormControl>
    );
}
